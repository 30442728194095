@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");


/* modal */
.custom_overlay {
  background: rgba(15, 15, 15, 0.9) !important;
}
.custom_modal{
  @apply !py-[2.25rem] !px-[1.15rem] sm:!py-[1.88rem] sm:!px-[3.5rem] md:!py-[1.88rem] md:!px-[3.5rem] focus:outline-none !m-0;
}
.custom_modal{
  border-radius: 2.5rem !important;
  border: 1px solid #AB8E4A !important;
  background: rgba(28, 28, 28, 0.80) !important;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.60) !important;
  backdrop-filter: blur(6px) !important;
}
.modal_card_bg {
  border-radius: 2.5rem;
  border: 1px solid #AB8E4A;
  background: rgba(28, 28, 28, 0.80) !important;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.60) !important;
  backdrop-filter: blur(6px);
}
html,body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  background-color: #0f0f0f;
}
.raleway {
  font-family: "Raleway", sans-serif;
}
.mobile-headline {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0%;
  font-weight: 700;
  text-decoration: none;
  font-size: 2.5rem;
  line-height: 100%;
}
.mobile-title-32 {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 2%;
  font-weight: 900;
  text-decoration: none;
  font-size: 2rem;
  line-height: 110%;
}
.mobile-title-20 {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 2%;
  font-weight: 900;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 110%;
}
.mobile-body-14 {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 2%;
  font-weight: 600;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 140%;
}
.mobile-body-12-bold {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 0.075rem;
  font-weight: 700;
  text-decoration: none;
  font-size: 0.75rem;
  line-height: 140%;
}
.mobile-body-12-regular {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 10%;
  font-weight: 400;
  text-decoration: none;
  font-size: 0.75rem;
  line-height: 140%;
}
.mobile-body-10-medium {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 10%;
  font-weight: 500;
  text-decoration: none;
  font-size: 0.625rem;
  line-height: 120%;
}

.mobile-body-18-regular {
  font-family: 'Pretendard', sans-serif;
  letter-spacing: 0%;
  font-weight: 400;
  text-decoration: none;
  font-size: 1.125rem;
  line-height: 140%;
}
.main_img_left-back {
  aspect-ratio: 432.799 / 575.77;
  max-width: 120.2%;
  position: absolute;
  top: 25%;
  left: -64%;
  transform: rotate(-75deg);
}
.shape{
  aspect-ratio: 302.14/180.06;
  max-width: 79.5%;
  position: absolute;
  top : 65.27%;
  left: -25.4%;
}
.main_video_left{
  aspect-ratio: 680/379;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  filter: blur(20px);
  top: 35%;
  left: -40%;
}
.main_video_right{
  aspect-ratio: 680/379;
  max-width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: lighten;
  top: 0;
  right: -18%;
  object-fit: inherit;
}
.main_gradation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(10, 10, 10, 0.00) 0%, rgba(10, 10, 10, 0.54) 69.28%, #0A0A0A 103.44%);
  width: 100%;
  height: 100%;
}

.abstract-left{
  max-width: 50%;
  position: absolute;
  top : 3.928%;
  left: -12.6%;
}
.abstract-right{
  max-width: 95%;
  position: absolute;
  top : 18.7%;
  left: 35%;
}
.ourvision{
  max-width: 94%;
  transform: rotate(135deg);
  position: absolute;
  top: 38%;
  left: 48%;
}
.token-bg{
  background: url('../public/assets/background/body/bg_tokenomics.png');
  background-size: 80%;
  background-repeat: repeat;
}

.ourdapps_back{
  max-width: 50%;
  position: absolute;
  top: 69%;
  left: 68.6%;
}
.ourdapps_front{
  max-width: 105%;
  transform: rotate(-135deg);
  position: absolute;
  top: 68.5%;
  left: 50%;
}

.press{
  max-width: 84.4%;
  transform: rotate(-34deg);
  position: absolute;
  top: 78%;
  left: -52.2%;
}

.bg_joinus{
  object-fit: cover;
  width: 100%;
  height: 15.615%;
  position: absolute;
  top: 83%;
  left: 0;
}
.video_joinus{
  width: 100%;
  height: 7.07%;
  position: absolute;
  top: 93.2%;
  left: 0;
  background: url('../public/assets/background/body/video_joinus.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.abstract_bg_img {
  border-radius: 1.5rem;
  border: 1px solid var(--line, #3D3D3D);
  background: url('../public/assets/abstract/card_top.png') lightgray -7px -15.371px / 104.487% 151.124% no-repeat;
}
.link_img_01{
  background: url('../public/assets/link/lbank.png') 1px 0px / 96.591% 100% no-repeat;
}
.link_img_02{
  background: url('../public/assets/link/xt.png') 0.25px 0px / 99.02% 100% no-repeat;
}
.link_img_03{
  background: url('../public/assets/link/2b.png') 0.75px 0px / 95% 100% no-repeat;
}

/* our_vision mobile*/
.our_vision_img_01{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/real-world-assets.png') lightgray 50% / cover no-repeat;
}
.our_vision_img_02{
  background: url('../public/assets/our_vision/contracted-mine.png') lightgray 50% / cover no-repeat;
  background-position: bottom center;
}
.our_vision_img_03{
  background: url('../public/assets/our_vision/profitable-minerals.png') lightgray 50% / cover no-repeat;
}
.our_vision_img_04{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/deposited-physical-gold.png') lightgray 50% / cover no-repeat;
}
.our_vision_img_05{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/smart-contract.png') lightgray 50% / cover no-repeat;
}
.our_vision_img_06{
  background: url('../public/assets/our_vision/comfortable-investment.png') lightgray 50% / cover no-repeat;
}

/* tokenomics */
.tokenomics_bg_img {
  border-radius: 1.5rem;
  border: 1px solid var(--line, #3D3D3D);
  background: url('../public/assets/tokenomics/bg_mobile.png') -7px -35px / 104.487% 151.124% no-repeat;
}
.tokenomics_img_01{
  background: url('../public/assets/tokenomics/icon_01.png')  50% / cover no-repeat;
}
.tokenomics_img_02{
  background: url('../public/assets/tokenomics/icon_02.png')  50% / cover no-repeat;
  
}
.tokenomics_img_03{
  background: url('../public/assets/tokenomics/icon_03.png')  50% / cover no-repeat;
  
}
.tokenomics_img_04{
  background: url('../public/assets/tokenomics/icon_04.png')  50% / cover no-repeat;
  
}


/* mobile menu list */
.menu-list{
  border: 1px solid var(--line, #3D3D3D);
  background: rgba(15, 15, 15, 0.83);
  backdrop-filter: blur(5px);
}
.border-rounded {
  border-radius: 0.75rem;
  border: 1px solid var(--line, #3D3D3D);
  background: #1A1A1A;
}
/* our dapps */
.custom-pagination{
  @apply flex flex-row justify-center items-center gap-[0.38rem]
  sm:gap-[0.75rem]
  md:gap-[1rem]
}
.custom-pagination .pagination {
  @apply w-[0.25rem] h-[0.25rem]
  sm:w-[0.5rem] sm:h-[0.5rem]
  md:w-[0.625rem] md:h-[0.625rem]
}
.pagination {
  background: url('../public/assets/our_dapps/dot.png');
  background-repeat:  no-repeat;
  background-size: cover;
}
.pagination.active {
  background: url('../public/assets/our_dapps/dot_active.png');
  background-repeat:  no-repeat;
  background-size: cover;
}
.cloud_mining_btn {
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(13px);
}

/* press */
.press_bg_img {
  border-radius: 1.5rem;
  border: 1px solid var(--line, #3D3D3D);
  box-shadow: -18.473px 18.473px 39.584px -16.493px rgba(0, 0, 0, 0.16), -18.473px 18.473px 39.584px -16.493px rgba(0, 0, 0, 0.16);
  background-color: #1C1C1C;
}
.press_img_01{
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  background: url('../public/assets/press/press_img_01.png') lightgray 50% / cover no-repeat;
}
.press_img_02{
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  background: url('../public/assets/press/press_img_02.png') lightgray 50% / cover no-repeat;
}
.press_img_03{
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  background: url('../public/assets/press/press_img_03.png') lightgray -73.595px 0 / 162.175% 100% no-repeat;
  background-blend-mode: normal, color-dodge, normal, normal;
}
.press_img_04{
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  background: url('../public/assets/press/press_img_04.png') lightgray 50% / cover no-repeat;
}
.joinus_frame {
  border-radius: 1.5rem;
  border: 1px solid var(--line, #3D3D3D);
  background: rgba(38, 38, 38, 0.50);
  box-shadow: -67.557px 63.335px 84.446px 0px rgba(0, 0, 0, 0.16), -23.645px 23.645px 50.668px -21.112px rgba(0, 0, 0, 0.16), -67.557px 63.335px 84.446px 0px rgba(0, 0, 0, 0.16), -23.645px 23.645px 50.668px -21.112px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(3.072000026702881px);
}

@media (min-width: 768px) {
  .tablet-headline {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0%;
    font-weight: 700;
    text-decoration: none;
    font-size: 5.5rem;
    line-height: 100%;
  }
  .tablet-title-64 {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 2%;
    font-weight: 900;
    text-decoration: none;
    font-size: 4rem;
    line-height: 110%;
  }
  .tablet-title-40 {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 2%;
    font-weight: 900;
    text-decoration: none;
    font-size: 2.5rem;
    line-height: 110%;
  }
  .tablet-body-32-semibold {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0%;
    font-weight: 600;
    text-decoration: none;
    font-size: 2rem;
    line-height: 140%;
  }
  .tablet-body-24-bold {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0.15rem;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 140%;
  }
  .tablet-body-24-regular {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 10%;
    font-weight: 400;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 140%;
  }
  .tablet-body-20-medium {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 10%;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 120%;
  }
  .tablet-title-36{
    font-family: 'Pretendard', sans-serif;
    font-size: 2.25rem;
    font-weight: 900;
    line-height: 110%; /* 2.475rem */
    letter-spacing: 0.045rem;
  }
  .tablet-body-18-regular {
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 140%;
  }
  
  .main_img_left-back {
    aspect-ratio: 923 / 1228;
    max-width: 120.18%;
    position: absolute;
    top: 19rem;
    left: -68.5%;
    transform: rotate(-75deg);
  }
  .shape{
    aspect-ratio: 611/345;
    max-width: 79.5%;
    position: absolute;
    top : 65.43%;
    left: -29.8%;
  }
  .main_video_left{
    aspect-ratio: 1451/809;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    filter: blur(20px);
    top: 47%;
    left: -55%;
  }
  .main_video_right{
    aspect-ratio: 1137/975;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: lighten;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  
  .abstract-left{
    max-width: 50%;
    position: absolute;
    top : 3.8%;
    left: -12.6%;
  }
  .abstract-right{
    aspect-ratio: 860 / 825;
    max-width: 111%;
    position: absolute;
    top : 15.8%;
    left: 35%;
  }
  .ourvision{
    aspect-ratio: 794 / 209;
    max-width: 96.72%;
    transform: rotate(135deg);
    position: absolute;
    top: 34.8%;
    left: 48%;
  }
  .token-bg{
    background: url('../public/assets/background/body/bg_tokenomics.png');
    background-size: 80%;
    background-repeat: repeat;
  }
  
  .ourdapps_back{
    max-width: 50%;
    position: absolute;
    top: 69%;
    left: 68.6%;
  }
  .ourdapps_front{
    max-width: 103.3%;
    transform: rotate(-135deg);
    position: absolute;
    top: 68.5%;
    left: 50%;
  }
  
  .press{
    max-width: 84.4%;
    transform: rotate(-34deg);
    position: absolute;
    top: 77%;
    left: -52.2%;
  }
  
  .bg_joinus{
    object-fit: cover;
    width: 100%;
    height: 17.17%;
    position: absolute;
    top: 83%;
    left: 0;
    /* background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, rgba(18, 18, 18, 0.78) 85.06%), linear-gradient(0deg, rgba(18, 18, 18, 0.60) 0%, rgba(18, 18, 18, 0.60) 100%), url('../public/assets/background/body/bg_joinus.svg') lightgray -426.147px -8.01px / 336.748% 100.252% no-repeat; */
  }
  .video_joinus{
    width: 100%;
    height: 7.07%;
    position: absolute;
    top: 93.2%;
    left: 0;
    background: url('../public/assets/background/body/video_joinus.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  

  .abstract_bg_img {
    border-radius: 2.5rem;
    border: 1px solid var(--line, #3D3D3D);
    background: url('../public/assets/abstract/card_top.png') lightgray -16.154px -37.039px / 104.487% 151.124% no-repeat;
  }

  .link_img_01{
    background: url('../public/assets/link/lbank.png') 2.136px 0px / 96.591% 100% no-repeat;
  }
  .link_img_02{
    background: url('../public/assets/link/xt.png') 0.534px 0px / 99.02% 100% no-repeat;
  }
  .link_img_03{
    background: url('../public/assets/link/2b.png') 1.6px 0px / 95% 100% no-repeat;
  }

  .our_vision_img_01{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/real-world-assets.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_02{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/contracted-mine.png') lightgray 0px -151.289px / 100% 160% no-repeat;
    background-position: bottom center;
  }
  .our_vision_img_03{
    background: url('../public/assets/our_vision/profitable-minerals.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_04{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/deposited-physical-gold.png') lightgray 50% / cover no-repeat, url('../public/assets/our_vision/deposited-physical-gold.png') lightgray -18.827px -41.296px / 110.149% 117.493% no-repeat;
  }
  .our_vision_img_05{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/smart-contract.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_06{
    background: url('../public/assets/our_vision/comfortable-investment.png') lightgray 50% / cover no-repeat;
  }
  .cloud_mining_btn {
    border-radius: 1.25rem;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(13px);
  }
  .press_bg_img {
    border-radius: 2.5rem;
    border: 1px solid var(--line, #3D3D3D);
    box-shadow: -39.408px 39.408px 84.446px -35.186px rgba(0, 0, 0, 0.16), -39.408px 39.408px 84.446px -35.186px rgba(0, 0, 0, 0.16);
  }
  .press_img_01{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_01.png') lightgray 50% / cover no-repeat;
  }
  .press_img_02{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_02.png') lightgray 50% / cover no-repeat;
    
  }
  .press_img_03{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_03.png') lightgray -157.11px 0 / 162.175% 100% no-repeat;
    background-blend-mode: normal, color-dodge, normal, normal;
  }
  .press_img_04{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_04.png') lightgray 50% / cover no-repeat;
  }
  .joinus_frame {
    border-radius: 2.5rem;
    border: 1px solid var(--line, #3D3D3D);
    background: rgba(38, 38, 38, 0.50);
    box-shadow: -144.121px 135.114px 180.152px 0px rgba(0, 0, 0, 0.16), -50.442px 50.442px 108.091px -45.038px rgba(0, 0, 0, 0.16), -144.121px 135.114px 180.152px 0px rgba(0, 0, 0, 0.16), -50.442px 50.442px 108.091px -45.038px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(6.553600311279297px);
  }
}

@media (min-width: 1280px) {
  .gnb_padding {
    padding-left : 8rem !important;
    padding-right: 8rem !important;
  }
  .abstract_margin_top{
    margin-top : -3.125rem !important;
  }
  .abstract_title{
    font-size: 24px !important;
  }
  .abstract_img_size {
    width: 10rem;
    height: 10rem;
  }
  .desktop-headline {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0%;
    font-weight: 700;
    text-decoration: none;
    font-size: 6.25rem;
    line-height: 100%;
  }
  
  .desktop-title-68 {
    font-family: 'Pretendard' sans-serif;
    font-size: 4.25rem;
    font-style: normal;
    font-weight: 900;
    line-height: 110%; /* 4.675rem */
    letter-spacing: 0.085rem;
  }
  
  .desktop-title-60 {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 2%;
    font-weight: 500;
    text-decoration: none;
    font-size: 3.75rem;
  }
  
  .desktop-title-36 {
    font-family: 'Pretendard', sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 2.475rem */
    letter-spacing: 0.045rem;
  }

  .desktop-title-36-bold {
    font-family: 'Pretendard', sans-serif;
    font-size: 2.25rem;
    font-weight: 900;
    line-height: 110%; /* 2.475rem */
    letter-spacing: 0.045rem;
  }
  
  .desktop-body-24-bold {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: -2%;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 140%;
  }
  .desktop-body-20-bold {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0.15rem;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 140%;
  }
  .desktop-body-20-regular {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0%;
    font-weight: 400;
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 140%;
  }
  .desktop-body-18-regular {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0%;
    font-weight: 400;
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 140%;
  }
  .desktop-body-16-medium {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0%;
    font-weight: 500;
    text-decoration: none;
    font-size: 1rem;
    line-height: 140%;
  }
  .desktop-body-16-regular {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: 0%;
    font-weight: 400;
    text-decoration: none;
    font-size: 1rem;
    line-height: 140%;
  }

  .main_img_left-back {
    aspect-ratio: 1119 / 1491;
    width: 58.26%;
    position: absolute;
    top: -0.5rem;
    left: -24%;
    transform: rotate(-75deg);
  }
  .shape{
    aspect-ratio: 816/459;
    width: 42.5%;
    position: absolute;
    top : 61.8%;
    left: -3.54%;
  }
  .main_video_left{
    aspect-ratio: 1920/1070;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    filter: blur(20px);
    top: 35%;
    left: -30%;
  }
  .main_video_right{
    aspect-ratio: 1920/1081;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: lighten;
    top: 0;
    left: 40%;
    object-fit: inherit;
  }

  .abstract-left{
    max-width: 25%;
    position: absolute;
    top : 3.4%;
    left: 6.5%;
  }
  .abstract-right{
    aspect-ratio: 1018 / 978;
    max-width: 53%;
    position: absolute;
    top : 4.3%;
    left: 68%;
  }
  .ourvision{
    aspect-ratio: 1842 / 490;
    max-width: 96.72%;
    transform: rotate(135deg);
    position: absolute;
    top: 32%;
    left: 50%;
  }
  .token-bg{
    background: url('../public/assets/background/body/bg_tokenomics.png');
    background-size: 80%;
    background-repeat: repeat;
  }
  .tokenomics{
    aspect-ratio: 1425 / 379;
    transform: rotate(45deg);
    max-width: 74.2%;
    position: absolute;
    top : 43.5%;
    left: -37.22%;
  }
  
  .ourdapps_back{
    max-width: 50%;
    position: absolute;
    top: 58%;
    left: 68.6%;
  }
  .ourdapps_front{
    max-width: 83.75%;
    transform: rotate(-135deg);
    position: absolute;
    top: 62.3%;
    left: 58%;
  }
  
  .press{
    max-width: 84.4%;
    transform: rotate(-34deg);
    position: absolute;
    top: 72%;
    left: -49.5%;
  }
  
  .bg_joinus{
    object-fit: cover;
    width: 100%;
    height: 17.17%;
    position: absolute;
    top: 81.1%;
    left: 0;
    /* background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, rgba(18, 18, 18, 0.78) 85.06%), linear-gradient(0deg, rgba(18, 18, 18, 0.60) 0%, rgba(18, 18, 18, 0.60) 100%), url('../public/assets/background/body/bg_joinus.svg') lightgray -426.147px -8.01px / 336.748% 100.252% no-repeat; */
  }
  .video_joinus{
    width: 64%;
    height: 7.07%;
    position: absolute;
    top: 91%;
    left: 18.5%;
    background: url('../public/assets/background/body/video_joinus.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .abstract_bg_img {
    border-radius: 2.5rem;
    border: 1px solid var(--line, #3D3D3D);
    background: url('../public/assets/abstract/card_top.png') lightgray -16.251px -27.22px / 105.763% 164.184% no-repeat;
  }
  .link_img_01{
    background: url('../public/assets/link/lbank.png') 2px 0px / 96.591% 100% no-repeat;
  }
  .link_img_02{
    background: url('../public/assets/link/xt.png') 0.5px 0px / 99.02% 100% no-repeat;
  }
  .link_img_03{
    background: url('../public/assets/link/2b.png') 1.5px 0px / 95% 100% no-repeat;
  }
  .our_vision_img_01{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/real-world-assets.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_02{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/contracted-mine.png') lightgray 0px -244.39px / 100% 160% no-repeat;
    background-position: bottom center;
  }
  .our_vision_img_03{
    background: url('../public/assets/our_vision/profitable-minerals.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_04{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/deposited-physical-gold.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_05{
    border-radius: 1.5rem;
    border: 1px solid var(--line, #3D3D3D);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 22.01%, rgba(0, 0, 0, 0.30) 55.55%, rgba(0, 0, 0, 0.50) 100%), url('../public/assets/our_vision/smart-contract.png') lightgray 50% / cover no-repeat;
  }
  .our_vision_img_06{
    background: url('../public/assets/our_vision/comfortable-investment.png') lightgray 50% / cover no-repeat;
  }
  .cloud_mining_btn {
    border-radius: 1.25rem;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(13px);
  }
  .press_bg_img {
    border-radius: 2.5rem;
    border: 1px solid var(--line, #3D3D3D);
    box-shadow: -46.181px 46.181px 98.96px -41.233px rgba(0, 0, 0, 0.16), -46.181px 46.181px 98.96px -41.233px rgba(0, 0, 0, 0.16);
  }
  .press_img_01{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_01.png') lightgray 50% / cover no-repeat;
  }
  .press_img_02{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_02.png') lightgray 50% / cover no-repeat;
  }
  .press_img_03{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background: url('../public/assets/press/press_img_03.png') lightgray -143.35px 0 / 162.175% 100% no-repeat;
    background-blend-mode: normal, color-dodge, normal, normal;
  }
  .press_img_04{
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    background:  url('../public/assets/press/press_img_04.png') lightgray 50% / cover no-repeat;
  }
}
@media (min-width: 1800px) {
  .gnb_padding {
    padding-left : 16rem !important;
    padding-right: 16rem !important;
  }
  .abstract_margin_top{
    margin-top : -6.25rem !important;
  }
  .abstract_title{
    font-size: 2.25rem !important;
  }
  .abstract_img_size {
    width: 14rem;
    height: 14rem;
  }
}

.content-top {
  opacity: 0;
  transform: translateY(-50px); /* 위에서 시작 */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.content-bottom {
  opacity: 0;
  transform: translateY(50px); /* 아래에서 시작 */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.content-left {
  opacity: 0;
  transform: translateX(-250px); /* 위에서 시작 */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.content-right {
  opacity: 0;
  transform: translateX(250px); /* 위에서 시작 */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.content {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-in {
  opacity: 1;
  transform: translate(0);
}