.rectangle-4257 {
    border-radius: 1.875rem;
    border-width: 0.125rem;
    border-style: solid;
    border-image: linear-gradient(
      180deg,
      rgba(229, 177, 65, 1) 0%,
      rgba(153, 113, 18, 1) 100%
    );
    border-image-slice: 1;
    width: 4.75rem;
    height: 4.75rem;
    position: absolute;
    left: calc(50% - 2.375rem);
    top: calc(50% - 2.375rem);
    backdrop-filter: blur(0.296875rem);
    object-fit: cover;
  }
  .group-37263 {
    height: auto;
    position: absolute;
    left: calc(50% - 1.875rem);
    top: calc(50% - 1.25rem);
    overflow: visible;
  }